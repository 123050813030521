import React, { useState } from "react";
import { Box, Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useAuth } from "../auth/AuthProvider";
import LogoOne from "./LogoOne.png";
import { useTheme } from "@mui/material/styles"; // Import theme

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const { login } = useAuth();
  const theme = useTheme(); // Access theme colors

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    try {
      await login(email, password);
      // console.log("Navigating to /verify-mfa after login");
    } catch (error: any) {
      console.error("Login error:", error);
      setError(error.message || "An error occurred during login.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
          boxShadow: `0px 4px 20px ${theme.palette.gray.main}`, // Slight shadow using gray color
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: theme.palette.rose.main, // Use rose for heading text color
            mb: 2,
          }}
        >
          Login
        </Typography>
        <img src={LogoOne} height={35} alt="The Alpine Studio Logo" />
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              backgroundColor: theme.palette.background.paper, // Light background for text fields
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              backgroundColor: theme.palette.background.paper, // Light background for text fields
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
              '&:hover': {
                backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
              },
            }}
          >
            Login
          </Button>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
