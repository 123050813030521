// src/admin/components/ProductForm.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  Alert,
} from "@mui/material";
import { useSidebar } from "../context/AdminNavBarContext"; // Import SidebarContext
import { useTheme } from "@mui/material/styles"; // Import theme
import { Product, Size, categories, Category } from "../auth/types"; // Import categories and Category
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent

const heightOptions = ["8", "10", "12", "14", "16", "18"];
const widthOptions = ["8", "10", "12", "14", "16", "18"];
const clothingSizeOptions = ["XS", "S", "M", "L", "XL", "XXL"];

const ProductForm = ({
  onSave,
  product,
}: {
  onSave: (product: Product) => void;
  product?: Product;
}) => {
  const [title, setTitle] = useState<string>(product?.title || "");
  const [description, setDescription] = useState<string>(
    product?.description || ""
  );
  const [sizes, setSizes] = useState<Size[]>(product?.sizes || []);
  const isEditing = !!product;
  const [image, setImage] = useState<string>(product?.imageUrl || ""); // Ensure correct field name

  // Initialize 'category' as type 'Category' with a default value
  const [category, setCategory] = useState<Category>(
    product?.category || categories[0]
  );

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const theme = useTheme(); // Access theme colors
  const { expanded } = useSidebar(); // Get sidebar state

  // Type Guard to ensure value is a valid Category
  const isCategory = (value: any): value is Category => {
    return categories.includes(value);
  };

  const handleCategoryChange = (e: SelectChangeEvent<Category>) => {
    const value = e.target.value;
    if (isCategory(value)) {
      setCategory(value);
    } else {
      setErrorMessage("Invalid category selected.");
    }
  };

  useEffect(() => {
    // Reset sizes when category changes
    if (category === "Shirts" || category === "Hoodies") {
      // Initialize with predefined sizes
      setSizes(
        clothingSizeOptions.map((size) => ({
          id: uuidv4(), // Add unique id for each size
          size,
          price: { amount: 0, currency: "USD" },
        }))
      );
    } else if (
      category === "Limited Edition" ||
      category === "Prints" ||
      category === "Original Scratchboards"
    ) {
      // Initialize with one default size if none exist
      if (sizes.length === 0) {
        setSizes([
          { id: uuidv4(), size: "8x10", price: { amount: 0, currency: "USD" } },
        ]);
      }
    } else if (category === "Stickers") {
      // For Stickers, no sizes are needed; initialize with a single price entry
      setSizes([{ id: uuidv4(), price: { amount: 0, currency: "USD" } }]);
    }
  }, [category]);

  const handleAddSize = () => {
    if (
      category === "Limited Edition" ||
      category === "Prints" ||
      category === "Original Scratchboards"
    ) {
      setSizes([
        ...sizes,
        { id: uuidv4(), size: "8x10", price: { amount: 0, currency: "USD" } },
      ]);
    }
  };

  const handlePriceChange = (id: string, value: string) => {
    const amount = value === "" ? undefined : parseFloat(value);

    const updatedSizes = sizes.map((size) =>
      size.id === id ? { ...size, price: { ...size.price, amount } } : size
    );
    setSizes(updatedSizes);
  };

  const handleSizeChange = (
    index: number,
    field: "height" | "width",
    value: string
  ) => {
    const updatedSizes = sizes.map((size, i) => {
      if (i !== index) return size;
      if (
        category === "Prints" ||
        category === "Original Scratchboards" ||
        category === "Limited Edition"
      ) {
        let newSize = size.size || "";
        if (field === "height") {
          const width = newSize.split("x")[1] || "";
          newSize = `${value}x${width}`;
        } else if (field === "width") {
          const height = newSize.split("x")[0] || "";
          newSize = `${height}x${value}`;
        }
        return { ...size, size: newSize };
      }
      return size;
    });
    setSizes(updatedSizes);
  };

  const handleRemoveSize = (id: string) => {
    setSizes(sizes.filter((size) => size.id !== id));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result as string);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log("handleSubmit called"); // Debugging log
    setErrorMessage(null); // Reset error message
    setSuccessMessage(null); // Reset success message
    setIsSubmitting(true); // Set loading state

    // Validate required fields
    if (!title.trim() || !description.trim() || !category || !image) {
      setErrorMessage(
        "Please fill in all required fields and upload an image."
      );
      setIsSubmitting(false);
      return;
    }

    // Additional validations based on category
    if (category === "Shirts" || category === "Hoodies") {
      for (const size of sizes) {
        // When validating prices
        if (size.price.amount === undefined || size.price.amount < 0) {
          setErrorMessage("Please provide valid prices for all sizes.");
          setIsSubmitting(false);
          return;
        }
      }
    } else if (
      category === "Prints" ||
      category === "Original Scratchboards" ||
      category === "Limited Edition"
    ) {
      for (const size of sizes) {
        if (!size.size || !size.price.amount || size.price.amount < 0) {
          setErrorMessage("Please provide valid sizes and prices.");
          setIsSubmitting(false);
          return;
        }
      }
    } else if (category === "Stickers") {
      if (!sizes[0].price.amount || sizes[0].price.amount < 0) {
        setErrorMessage("Please provide a valid price for Stickers.");
        setIsSubmitting(false);
        return;
      }
    }

    try {
      const newProduct: Product = {
        id: product?.id || uuidv4(), // Generate unique ID if new product
        title: title.trim(),
        description: description.trim(),
        sizes,
        prices: sizes.map((size) => ({
          amount: size.price.amount!,
          currency: size.price.currency,
        })),        imageUrl: image, // Ensure correct field name
        category,
        sold: product?.sold || false,
      };
      console.log("Submitting product:", newProduct); // Debugging log
      await onSave(newProduct); // Use the onSave prop
      console.log("Product submitted successfully");
      setSuccessMessage(
        product
          ? "Product updated successfully!"
          : "Product added successfully!"
      );

      // Clear form fields if creating a new product
      if (!product) {
        setTitle("");
        setDescription("");
        setCategory(categories[0]); // Reset to default category
        setImage("");
        setSizes([]);
      }
    } catch (error) {
      console.error("Failed to save product", error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Failed to save product. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  // Handlers for closing Snackbar alerts
  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMessage(null);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
        ml: expanded ? "240px" : "60px", // Adjust margin-left based on sidebar state
        transition: "margin-left 0.3s ease", // Smooth transition when sidebar toggles
        bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          color: theme.palette.rose.main, // Apply rose color to title
          fontWeight: "bold",
        }}
      >
        {product ? "Edit Product" : "Create Product"}
      </Typography>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="center">
        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
            required
            disabled={isEditing}
            sx={{
              backgroundColor: theme.palette.background.paper,
              mb: 2,
            }}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            fullWidth
            required
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          />
        </Grid>

        {/* Category Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              label="Category"
              onChange={handleCategoryChange}
              sx={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Price Fields for Shirts & Hoodies */}
        {(category === "Shirts" || category === "Hoodies") &&
          sizes.map((size, index) => (
            <Grid item xs={12} md={6} key={index}>
              <FormControl fullWidth required sx={{ mb: 2 }}>
                <TextField
                  label={`Price for ${size.size}`}
                  type="number"
                  value={
                    size.price.amount !== undefined ? size.price.amount : ""
                  }
                  onChange={(e) => handlePriceChange(size.id, e.target.value)}
                  variant="outlined"
                  fullWidth
                  required
                  sx={{ backgroundColor: theme.palette.background.paper }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          ))}

        {/* Price Field for Stickers */}
        {category === "Stickers" && (
          <Grid item xs={12} md={6}>
            <TextField
              label="Price"
              type="number"
              value={sizes[0]?.price.amount || ""}
              onChange={(e) => handlePriceChange(sizes[0].id, e.target.value)}
              variant="outlined"
              fullWidth
              required
              sx={{ backgroundColor: theme.palette.background.paper }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}

        {/* Size and Price Fields for Dynamic Categories */}
        {(category === "Prints" ||
          category === "Original Scratchboards" ||
          category === "Limited Edition") &&
          sizes.map((size, index) => {
            const [currentHeight, currentWidth] = size.size
              ? size.size.split("x")
              : ["", ""];
            return (
              <React.Fragment key={index}>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Height</InputLabel>
                    <Select
                      value={currentHeight}
                      label="Height"
                      onChange={(e) =>
                        handleSizeChange(index, "height", e.target.value)
                      }
                      sx={{ backgroundColor: theme.palette.background.paper }}
                    >
                      {heightOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth required>
                    <InputLabel>Width</InputLabel>
                    <Select
                      value={currentWidth}
                      label="Width"
                      onChange={(e) =>
                        handleSizeChange(index, "width", e.target.value)
                      }
                      sx={{ backgroundColor: theme.palette.background.paper }}
                    >
                      {widthOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Price"
                    type="number"
                    value={size.price.amount || ""}
                    onChange={(e) => handlePriceChange(size.id, e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleRemoveSize(size.id)}
                  >
                    Remove
                  </Button>
                </Grid>
              </React.Fragment>
            );
          })}

        {/* Add Size Button for Dynamic Categories */}
        {(category === "Prints" ||
          category === "Original Scratchboards" ||
          category === "Limited Edition") && (
          <Grid item xs={12}>
            <Button
              onClick={handleAddSize}
              variant="contained"
              sx={{ backgroundColor: theme.palette.rose.main }}
            >
              Add Size
            </Button>
          </Grid>
        )}

        {/* Image Upload */}
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ borderColor: theme.palette.gray.main }}
          >
            Upload Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
        </Grid>

        {/* Image Preview */}
        <Grid item xs={12} md={6}>
          {image && (
            <Box
              component="img"
              src={image}
              alt="Image Preview"
              sx={{
                maxHeight: 200,
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          )}
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{
              backgroundColor: theme.palette.lightBlue.main,
              "&:hover": { backgroundColor: theme.palette.lightBlue.dark },
            }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
