// App.tsx

import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../Context/theme";
import ProductView from "./components/ProductView";
import AdminSideBar from "./components/AdminSideBar";
import LoginForm from "./components/LoginForm";
import ProtectedRoute from "./auth/ProtectedRoute";
import AuthProvider from "./auth/AuthProvider";
import Dashboard from "./components/Dashboard";
import VerifyMFAForm from "./components/VerifyMFAForm";
import SignUp from "./components/SignUp";
import SetupAccount from "./components/SetupAccount";
import SetupTOTP from "./components/SetupTop";
import { SidebarProvider } from "./context/AdminNavBarContext";
import NavBar from "./components/NavBar";
import EditProductForm from "./components/EditProductForm";
import AddProductForm from "./components/AddProductForm";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SidebarProvider>
          <CssBaseline />

          <NavBar />

          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/verify-mfa" element={<VerifyMFAForm />} />
            <Route path="/setup-totp" element={<SetupTOTP />} />
            <Route path="/setup-account" element={<SetupAccount />} />

            {/* Wrap each protected route with ProtectedRoute */}
            <Route
              path="/add-product"
              element={
                <ProtectedRoute>
                  <AdminSideBar />
                  {/* Render Sidebar inside protected routes */}
                  <AddProductForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-products"
              element={
                <ProtectedRoute>
                  <AdminSideBar />
                  {/* Render Sidebar inside protected routes */}
                  <ProductView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <AdminSideBar />
                  {/* Render Sidebar inside protected routes */}
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit-product/:id"
              element={
                <ProtectedRoute>
                  <AdminSideBar />
                  <EditProductForm />
                </ProtectedRoute>
              }
            />
          </Routes>
        </SidebarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
