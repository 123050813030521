import React, { useState } from "react";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useAuth } from "../auth/AuthProvider";
import { useTheme } from "@mui/material/styles"; // Import theme

const VerifyMFA: React.FC = () => {
  const [mfaCode, setMfaCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const { verifyMFA } = useAuth();
  const theme = useTheme(); // Access theme colors

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const username = localStorage.getItem("username");
    const session = localStorage.getItem("session");

    if (username && session) {
      try {
        await verifyMFA(username, session, mfaCode);
        localStorage.removeItem("password");
        localStorage.removeItem("session");
      } catch (error) {
        setError("MFA verification failed");
      }
    } else {
      setError("An error occurred. Please try logging in again.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
          boxShadow: `0px 4px 20px ${theme.palette.gray.main}`, // Apply gray shadow for depth
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: theme.palette.rose.main, // Apply rose color to title
            mb: 2,
          }}
        >
          Verify MFA
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: "100%", marginTop: 16 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="mfaCode"
            label="MFA Code"
            name="mfaCode"
            autoComplete="off"
            autoFocus
            value={mfaCode}
            onChange={(e) => setMfaCode(e.target.value)}
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
              '&:hover': {
                backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
              },
            }}
          >
            Verify
          </Button>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </form>
      </Paper>
    </Container>
  );
};

export default VerifyMFA;
