// src/admin/auth/types.ts

export type Category =
  | "All" // Used for filtering only
  | "Originals"
  | "Limited Edition Prints"
  | "Open Edition Prints and Stickers"
  | "Apparel";

export type Subcategory =
  | "Original Scratchboards"
  | "Prints"
  | "Stickers"
  | "Shirts"
  | "Hoodies";

  export interface FetchProductsParams {
    category?: string;
    subcategories?: string[];
    sort?: string;
    search?: string;
    page: number;
    limit: number;
  }
  
  export interface FetchProductsResponse {
    items: Product[];
    totalPages: number;
  }

export interface Price {
  amount: number;
  currency: string;
}

export interface PriceInput {
  amount?: number; // amount is optional for form input
  currency: string;
}

export interface Size {
  size?: string;
  price: {
    amount?: number;
    currency: string;
  };
}

export interface SizeWithId extends Size {
  id: string;
}

export interface Product {
  id: string;
  title: string;
  description: string;
  category: Category;
  subcategories: Subcategory[];
  sizes: Size[];
  imageUrl: string;
  sold: boolean;
}


export const categories: Category[] = [
  "All",
  "Originals",
  "Limited Edition Prints",
  "Open Edition Prints and Stickers",
  "Apparel",
];

// Define subcategories mapping based on category
export const subcategoriesMap: { [key in Exclude<Category, "All">]: Subcategory[] } = {
  "Originals": ["Original Scratchboards"],
  "Limited Edition Prints": ["Prints"],
  "Open Edition Prints and Stickers": ["Prints", "Stickers"],
  "Apparel": ["Shirts", "Hoodies"],
};

