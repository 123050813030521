// src/components/ProductView.tsx

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Product, Subcategory, Category, categories } from "../auth/types"; // Import necessary types
import { useSidebar } from "../context/AdminNavBarContext"; // Context for sidebar state
import { useTheme } from "@mui/material/styles"; // Import theme
import { useAuth } from "../auth/AuthProvider";
import { debounce } from "lodash"; // Install lodash if not already installed

const ProductView = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const { expanded } = useSidebar(); // Get the expanded state of the sidebar
  const theme = useTheme(); // Access theme colors
  const { markProductAsSold, deleteProduct, fetchProducts } = useAuth();

  // Dialog state
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  // Snackbar state
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Category, Subcategory, Sort Order, and Search State
  const [selectedCategory, setSelectedCategory] = useState<Category | "All">(
    "All"
  );
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    Subcategory[]
  >([]);
  const [sortOrder, setSortOrder] = useState<string>("asc"); // "asc" or "desc"
  const [searchKeyword, setSearchKeyword] = useState<string>("");

  // Loading state
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Function to get subcategories based on selected category
  const getSubcategories = (): Subcategory[] => {
    switch (selectedCategory) {
      case "Apparel":
        return ["Shirts", "Hoodies"];
      case "Open Edition Prints and Stickers":
        return ["Prints", "Stickers"];
      default:
        return [];
    }
  };

  // Function to fetch products
  const getProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      console.log(
        "Fetching products with category:",
        selectedCategory,
        "subcategories:",
        selectedSubcategories,
        "sort:",
        sortOrder,
        "search:",
        searchKeyword
      );

      const productsData = await fetchProducts({
        category: selectedCategory !== "All" ? selectedCategory : undefined,
        subcategories:
          selectedSubcategories.length > 0 ? selectedSubcategories : undefined,
        sort: sortOrder,
        searchKeyword,
      });
      setProducts(productsData);
    } catch (error) {
      console.error("Failed to fetch products:", error);
      setErrorMessage("Failed to fetch products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [
    fetchProducts,
    selectedCategory,
    selectedSubcategories,
    sortOrder,
    searchKeyword,
  ]);

  // Debounced effect for fetching products
  useEffect(() => {
    const delayedSearch = debounce(() => {
      getProducts();
    }, 500); // Adjust the delay as needed

    delayedSearch();

    // Cleanup function to cancel debounce on unmount or dependencies change
    return () => {
      delayedSearch.cancel();
    };
  }, [getProducts]);

  const handleDeleteClick = (product: Product) => {
    setSelectedProduct(product);
    setOpenDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (!selectedProduct) return;
    try {
      await deleteProduct(selectedProduct.id, selectedProduct.title);
      setSuccessMessage("Product deleted successfully!");
      setOpenDialog(false);
      setSelectedProduct(null);
      // Fetch the updated product list after deletion
      await getProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
      setErrorMessage("Error deleting product. Please try again.");
      setOpenDialog(false);
      setSelectedProduct(null);
    }
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMessage(null);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(null);
  };

  const handleMarkAsSold = async (productId: string) => {
    const product = products.find((p) => p.id === productId);
    if (!product) {
      setErrorMessage("Product not found.");
      return;
    }

    try {
      await markProductAsSold(productId, product.title); // Pass both productId and title
      setSuccessMessage("Product marked as sold!");
      await getProducts(); // Refresh the product list after marking as sold
    } catch (error) {
      console.error("Failed to mark product as sold:", error);
      setErrorMessage("Failed to mark product as sold. Please try again.");
    }
  };

  return (
    <Paper
      sx={{
        margin: 2,
        padding: 2,
        ml: expanded ? 32 : 10,
        transition: "margin 0.3s ease",
        bgcolor: theme.palette.lightCyan.main,
        boxShadow: 3,
      }}
    >
      {/* Search Input */}
      <TextField
        label="Search"
        variant="outlined"
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        sx={{ ml: 2, mb: 2 }}
      />

      <Box mb={2} display="flex" alignItems="center" flexWrap="wrap">
        {/* Category Filter */}
        <FormControl variant="outlined" sx={{ minWidth: 200, mr: 2, mb: 2 }}>
          <InputLabel id="category-select-label">Filter by Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value as Category | "All");
              setSelectedSubcategories([]); // Reset subcategories when category changes
            }}
            label="Filter by Category"
            aria-label="Filter products by category"
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            {categories.map((categoryOption) => (
              <MenuItem key={categoryOption} value={categoryOption}>
                {categoryOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Subcategory Filter */}
        {getSubcategories().length > 0 && (
          <FormControl variant="outlined" sx={{ minWidth: 200, mr: 2, mb: 2 }}>
            <InputLabel id="subcategory-select-label">
              Filter by Subcategory
            </InputLabel>
            <Select
              labelId="subcategory-select-label"
              id="subcategory-select"
              multiple
              value={selectedSubcategories}
              onChange={(e) =>
                setSelectedSubcategories(e.target.value as Subcategory[])
              }
              label="Filter by Subcategory"
              aria-label="Filter products by subcategory"
              renderValue={(selected) => selected.join(", ")}
            >
              {getSubcategories().map((subcategoryOption) => (
                <MenuItem key={subcategoryOption} value={subcategoryOption}>
                  <Checkbox
                    checked={selectedSubcategories.includes(subcategoryOption)}
                  />
                  <ListItemText primary={subcategoryOption} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Sort Order */}
        <FormControl variant="outlined" sx={{ minWidth: 150, mb: 2 }}>
          <InputLabel id="sort-select-label">Sort Order</InputLabel>
          <Select
            labelId="sort-select-label"
            id="sort-select"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            label="Sort Order"
            aria-label="Sort products by order"
          >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Button
        component={Link}
        to="/add-product"
        variant="contained"
        sx={{
          backgroundColor: theme.palette.lightBlue.main,
          mb: 2,
          "&:hover": {
            backgroundColor: theme.palette.lightBlue.dark,
          },
        }}
      >
        Add New Product
      </Button>

      {/* Display Loading State */}
      {isLoading ? <Typography>Loading products...</Typography> : null}

      {/* Product Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: theme.palette.rose.main }}>Image</TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>Title</TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Description
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Category
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Subcategories
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Sizes and Prices
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>Edit</TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Delete
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Typography>Loading products...</Typography>
              </TableCell>
            </TableRow>
          ) : products.length === 0 ? (
            <TableRow>
              <TableCell colSpan={9} align="center">
                <Typography variant="h6">No products available.</Typography>
              </TableCell>
            </TableRow>
          ) : (
            products.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  {product.imageUrl && (
                    <Box
                      component="img"
                      src={product.imageUrl}
                      alt={`${product.title} Thumbnail`}
                      sx={{
                        maxHeight: 100,
                        maxWidth: 100,
                        objectFit: "cover",
                        borderRadius: 2,
                        boxShadow: 1,
                      }}
                      loading="lazy"
                    />
                  )}
                </TableCell>
                <TableCell>{product.title}</TableCell>
                <TableCell>{product.description}</TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>
                  {product.subcategories ? product.subcategories : "N/A"}
                </TableCell>
                <TableCell>
                  {product.sizes.map((size, index) => (
                    <Typography
                      key={index}
                      sx={{ color: theme.palette.cadetGray.main }}
                    >
                      {size.size}: $
                      {size.price.amount
                        ? size.price.amount.toFixed(2)
                        : "N/A"}{" "}
                      {size.price.currency}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/edit-product/${product.id}`}
                    variant="contained"
                    sx={{
                      backgroundColor: theme.palette.rose.main,
                      "&:hover": { backgroundColor: theme.palette.rose.dark },
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleDeleteClick(product)}
                    variant="contained"
                    color="secondary"
                    sx={{
                      backgroundColor: theme.palette.gray.main,
                      "&:hover": { backgroundColor: theme.palette.gray.dark },
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    onClick={() => handleMarkAsSold(product.id)}
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: theme.palette.lightBlue.main,
                      "&:hover": {
                        backgroundColor: theme.palette.lightBlue.dark,
                      },
                    }}
                    disabled={product.sold || product.category !== "Originals"}
                  >
                    {product.sold ? "Sold" : "Mark as Sold"}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the product "
            {selectedProduct?.title}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ProductView;
