import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  configLogin,
  configConfirmSignup,
  configSignup,
  configVerify,
  configTotp,
  configRefreshToken,
  configUploadProduct,
  configMarkProductAsSold,
  configFetchProducts,
  configDeleteProduct,
  configFetchProductById,
  configEditProduct,
} from "./aws-config";
import { Product, Subcategory } from "./types";

interface SignUpResponse {
  message: string;
  totp_secret?: string;
  access_token?: string;
}

interface LoginResponse {
  challenge_name?: string;
  session?: string;
  id_token?: string;
  access_token?: string;
  refresh_token?: string;
  message?: string;
}

interface FetchProductsParams {
  category?: string;
  subcategories?: Subcategory[];
  sort?: string;
  searchKeyword?: string;
}


export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  signup: (
    email: string,
    password: string,
    username: string,
    phoneNumber: string
  ) => Promise<void>;
  confirmSignUp: (
    email: string,
    confirmationCode: string,
    password: string,
    username: string,
    phoneNumber: string
  ) => Promise<{ totp_secret: string; access_token: string }>;
  setupTOTP: (
    email: string,
    accessToken: string,
    userCode: string
  ) => Promise<void>;
  login: (email: string, password: string) => Promise<LoginResponse>;
  verifyMFA: (
    username: string,
    session: string, // This should use session for the MFA verification
    mfaCode: string
  ) => Promise<void>;
  logout: () => void;
  uploadProduct: (product: Product) => Promise<void>;
  markProductAsSold: (productId: string, title: string) => Promise<void>;
  fetchProducts: (params?: FetchProductsParams) => Promise<Product[]>;

  deleteProduct: (productId: string, title: string) => Promise<void>;
  editProduct: (product: Product) => Promise<void>;
  fetchProductById: (productId: string) => Promise<Product>;
}

const defaultContext: AuthContextType = {
  isAuthenticated: false,
  isLoading: true,

  signup: async () => {
    throw new Error("Context not implemented");
  },
  confirmSignUp: async () => {
    throw new Error("Context not implemented");
  },
  setupTOTP: async () => {
    throw new Error("Context not implemented");
  },
  login: async () => {
    throw new Error("Context not implemented");
  },
  logout: () => {
    console.error("Context not implemented");
  },
  verifyMFA: async () => {
    throw new Error("Context not implemented");
  },
  uploadProduct: async () => {
    throw new Error("Context not implemented");
  },
  markProductAsSold: async () => {
    throw new Error("Context not implemented");
  },
  fetchProducts: async (params?: {
    category?: string;
    subcategories?: Subcategory[];
    sort?: string;
    searchKeyword?: string;
  }): Promise<Product[]> => {
    throw new Error("Context not implemented");
  },
  deleteProduct: async () => {
    throw new Error("Context not implemented");
  },
  editProduct: async () => {
    throw new Error("Context not implemented");
  },
  fetchProductById: async () => {
    throw new Error("Context not implemented");
  },
};

export const AuthContext = createContext<AuthContextType | undefined>(
  defaultContext
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const isAuth = localStorage.getItem("isAuthenticated");

      if (isAuth === "true" && accessToken && !isTokenExpired(accessToken)) {
        console.log("Token is valid on page load.");
        setIsAuthenticated(true);
      } else if (refreshToken) {
        console.log("Token is missing or expired. Attempting to refresh.");
        await refreshAccessToken();
      } else {
        console.log("Token is missing or expired.");
        logout();
      }
      setIsLoading(false); // Set loading to false after token check
    };

    checkToken();
  }, []);

  // Updated `isTokenExpired` function for better handling.
  const isTokenExpired = (token: string): boolean => {
    try {
      const { exp } = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Math.floor(Date.now() / 1000);
      return currentTime > exp;
    } catch (e) {
      console.error("Error decoding token:", e);
      return true;
    }
  };

  const checkAndRefreshToken = async (): Promise<boolean> => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");

    //If no token return false
    if (!accessToken || !refreshToken) {
      return false;
    }

    //If access token exisit but is expired, try refreshing.
    if (accessToken && isTokenExpired(accessToken)) {
      console.log("Token is expired. Attempting to refresh.");

      if (refreshToken) {
        try {
          await refreshAccessToken();
          return true;
        } catch (error) {
          console.error("Error refreshing token:", error);
          return false; //Refresh failed
        }
      }
    }

    //if the access token is still valid return true.
    return !isTokenExpired(accessToken);
  };

  const refreshAccessToken = async (): Promise<void> => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const email = localStorage.getItem("username"); // Using email instead of username

      if (!refreshToken || !email) {
        console.error("Missing refresh token or email in localStorage");
        logout(); // Force logout if critical tokens are missing
        return;
      }

      console.log("Attempting to refresh with token:", refreshToken);

      const response = await fetch(configRefreshToken.API_ENDPOINT_REFRESH, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: refreshToken, email }),
      });

      if (!response.ok) {
        throw new Error("Token refresh failed");
      }

      const data = await response.json();
      console.log("Refreshed tokens data:", data);

      if (data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken); // Make sure to store the access token
      } else {
        console.error("No access token received from the refresh call.");
      }

      if (data.id_token) {
        localStorage.setItem("idToken", data.id_token); // Make sure to store the id token
      }

      if (data.refresh_token) {
        localStorage.setItem("refreshToken", data.refresh_token); // Update refresh token if a new one is provided
      }

      setIsAuthenticated(true);
      console.log("Token refreshed successfully.");
    } catch (error) {
      console.error("Error during token refresh:", error);
      logout();
    }
  };

  const signup = async (
    email: string,
    password: string,
    username: string,
    phoneNumber: string
  ): Promise<void> => {
    try {
      const formattedPhoneNumber = `+1${phoneNumber}`;
      const requestBody = {
        email,
        password,
        username,
        phone_number: formattedPhoneNumber,
      };

      const response = await fetch(configSignup.API_ENDPOINT_SIGNUP, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      const data: SignUpResponse = await response.json();
      if (response.ok) {
        alert(data.message);
      } else {
        throw new Error(data.message || "Sign up failed");
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("An unexpected error occurred");
      }
    }
  };

  const confirmSignUp = async (
    email: string,
    confirmationCode: string,
    password: string,
    username: string,
    phoneNumber: string
  ): Promise<{ totp_secret: string; access_token: string }> => {
    try {
      const payload = {
        email,
        confirmationCode,
        password,
        username,
        phone_number: phoneNumber,
      };

      const response = await fetch(
        configConfirmSignup.API_ENDPOINT_CONFIRMSIGNUP,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("TOTP Secret:", data.totp_secret); // Log TOTP secret
        console.log("Access Token:", data.access_token); // Log access token

        localStorage.setItem("access_token", data.access_token || "");
        localStorage.setItem("username", email);
        localStorage.setItem("password", password);

        return {
          totp_secret: data.totp_secret,
          access_token: data.access_token,
        };
      } else {
        throw new Error(data.message || "Account confirmation failed");
      }
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  const setupTOTP = async (
    email: string,
    accessToken: string,
    userCode: string
  ): Promise<void> => {
    if (!email || !accessToken || !userCode) {
      throw new Error("Missing email, accessToken, or user code");
    }

    try {
      const payload = {
        email,
        accessToken,
        userCode,
      };
      console.log("Sending payload for TOTP setup:", JSON.stringify(payload));

      const response = await fetch(configTotp.API_ENDPOINT_TOTP, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (response.ok) {
        console.log("TOTP setup successful:", data.message);
      } else {
        throw new Error(data.message || "TOTP setup failed");
      }
    } catch (error) {
      throw new Error((error as Error).message);
    }
  };

  const login = async (
    email: string,
    password: string
  ): Promise<LoginResponse> => {
    try {
      const response = await fetch(configLogin.API_ENDPOINT_LOGIN, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data: LoginResponse = await response.json();
      console.log("Login response data:", data); // Log the entire response data

      if (response.ok) {
        localStorage.setItem("username", email);
        console.log("Username stored:", email);

        // Check if MFA is required
        if (data.challenge_name === "SOFTWARE_TOKEN_MFA") {
          if (data.session) {
            localStorage.setItem("session", data.session); // Store session
            console.log("Session stored:", data.session); // Log session storage
          } else {
            console.error("Session token is missing in response");
          }
          navigate("/verify-mfa"); // Navigate only after session is stored
          return { challenge_name: data.challenge_name, session: data.session };
        } else {
          // Handle case where MFA is not required
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem("idToken", data.id_token || "");
          localStorage.setItem("token", data.access_token || "");
          navigate("/dashboard");
          return { access_token: data.access_token };
        }
      } else {
        throw new Error(data.message || "Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
      throw new Error((error as Error).message);
    }
  };

  const verifyMFA = async (
    username: string,
    session: string,
    mfaCode: string
  ): Promise<void> => {
    console.log("Attempting to verify MFA with", {
      username,
      session,
      mfaCode,
    });

    try {
      const response = await fetch(configVerify.API_ENDPOINT_VERIFY, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, session, mfa_code: mfaCode }),
      });

      // Parse the response body (this can only be done once)
      const data = await response.json();
      console.log("Full response data:", data);

      // Process the data
      console.log("MFA verification successful:", data.message);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("accessToken", data.token || "");
      localStorage.setItem("idToken", data.id_token || "");
      localStorage.setItem("refreshToken", data.refresh_token || "");
      setIsAuthenticated(true);

      // Navigate after success
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during MFA verification:", error);
      throw new Error((error as Error).message);
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("idToken");
    localStorage.removeItem("challengeName");
    localStorage.removeItem("session");
    localStorage.removeItem("username");
  };

  const uploadProduct = async (product: Product): Promise<void> => {
    try {
      // Ensure token is valid or refresh it if expired
      const tokenValid = await checkAndRefreshToken();
      if (!tokenValid) {
        throw new Error(
          "Access token is missing or invalid. Please login again."
        );
      }

      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("Access token is missing");

      const response = await fetch(
        configUploadProduct.API_ENDPOINT_UPLOAD_PRODUCT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${localStorage.getItem("idToken")}`, // Ensure Authorization header is correctly set
          },
          body: JSON.stringify(product),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Product upload failed", errorText);
        throw new Error("Product upload failed");
      }

      console.log("Product uploaded successfully");
    } catch (error) {
      console.error("Error during product upload:", error);
      throw error;
    }
  };

  const markProductAsSold = async (
    productId: string,
    title: string
  ): Promise<void> => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("User is not authenticated");

      const response = await fetch(
        `${configMarkProductAsSold.API_ENDPOINT_MARK_PRODUCT_AS_SOLD}/${productId}`,
        {
          method: "POST", // or PUT depending on your API
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ title }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to mark product as sold");
      }

      console.log("Product marked as sold successfully");
    } catch (error) {
      console.error("Error marking product as sold:", error);
      throw error;
    }
  };

  const fetchProducts = useCallback(
    async ({
      category,
      subcategories,
      sort = "asc",
      searchKeyword = "",
    }: {
      category?: string;
      subcategories?: Subcategory[];
      sort?: string;
      searchKeyword?: string;
    } = {}): Promise<Product[]> => {
      console.log("fetchProducts called with:", {
        category,
        subcategories,
        sort,
        searchKeyword,
      });

      try {
        // Ensure token is valid or refresh it if expired
        const tokenValid = await checkAndRefreshToken();
        if (!tokenValid) {
          throw new Error(
            "Access token is missing or invalid. Please login again."
          );
        }

        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) throw new Error("Access token is missing");

        const url = new URL(configFetchProducts.API_ENDPOINT_FETCH_PRODUCTS);
        const params: any = {};

        if (category && category !== "All") {
          params.category = category;
        }

        if (subcategories && subcategories.length > 0) {
          params.subcategories = subcategories.join(",");
        }

        if (sort) {
          params.sortOrder = sort;
        }

        if (searchKeyword) {
          params.searchKeyword = searchKeyword;
        }

        // Append query parameters to the URL
        Object.keys(params).forEach((key) =>
          url.searchParams.append(key, params[key])
        );

        console.log("Constructed URL:", url.toString());

        const response = await fetch(url.toString(), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Ensure correct header format
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch products");
        }

        const products: Product[] = await response.json();
        console.log("Fetched products:", products);
        return products;
      } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
      }
    },
    []
  );
  
  

  const deleteProduct = async (
    productId: string,
    title: string
  ): Promise<void> => {
    try {
      // Ensure token is valid or refresh it if expired
      const tokenValid = await checkAndRefreshToken();
      if (!tokenValid) {
        throw new Error(
          "Access token is missing or invalid. Please login again."
        );
      }

      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("Access token is missing");

      const url = `${configDeleteProduct.API_ENDPOINT_DELETE_PRODUCT(
        productId
      )}?title=${encodeURIComponent(title)}`;

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to delete product:", errorText);
        throw new Error("Product deletion failed");
      }

      console.log(`Product with ID ${productId} deleted successfully`);
    } catch (error) {
      console.error("Error deleting product:", error);
      throw error;
    }
  };

  const fetchProductById = async (id: string): Promise<Product> => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("Access token is missing");

      console.log(`Fetching product with ID: ${id}`); // Debugging log

      const response = await fetch(
        configFetchProductById.API_ENDPOINT_FETCH_PRODUCT_BY_ID(id),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`, // Add Authorization header
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to fetch product:", errorText);
        throw new Error("Failed to fetch product");
      }

      const product: Product = await response.json();
      return product;
    } catch (error) {
      console.error("Error fetching product:", error);
      throw error;
    }
  };

  const editProduct = async (product: Product): Promise<void> => {
    try {
      // Ensure token is valid or refresh it if expired
      const tokenValid = await checkAndRefreshToken();
      if (!tokenValid) {
        throw new Error(
          "Access token is missing or invalid. Please login again."
        );
      }

      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) throw new Error("Access token is missing");

      // Construct the URL with the product ID
      const url = configEditProduct.API_ENDPOINT_EDIT_PRODUCT(product.id);

      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`, // Include the access token
        },
        body: JSON.stringify(product),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to edit product:", errorText);
        throw new Error("Product edit failed");
      }

      console.log(`Product with ID ${product.id} updated successfully`);
    } catch (error) {
      console.error("Error editing product:", error);
      throw error;
    }
  };
  

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        signup,
        confirmSignUp,
        setupTOTP,
        login,
        logout,
        verifyMFA,
        uploadProduct,
        markProductAsSold,
        fetchProducts,
        deleteProduct,
        editProduct,
        fetchProductById,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
