import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import LogoOne from "./LogoOne.png";
import { configPostConfirmation } from "../auth/aws-config";
import { useTheme } from "@mui/material/styles"; // Import theme

const SetupAccount = () => {
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme(); // Access theme colors

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const user = query.get('user');
    const code = query.get('code');
    const email = query.get('email');
    const phoneNumber = query.get('phone_number');
    if (user && code && email && phoneNumber) {
      setUsername(user);
      setCode(code);
      setEmail(email);
      setPhoneNumber(phoneNumber);
    } else {
      console.error("Missing query parameters in the URL");
    }
  }, [location.search]);

  const handleConfirmSignUp = async () => {
    setError(null);
    // console.log("Sending request with data:", { username, code, email, phoneNumber, password });

    try {
      const response = await fetch(
        `${configPostConfirmation.API_ENDPOINT_POSTCONFIRM}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username,
          code,
          email,
          phone_number: phoneNumber,
          password
        }),
      });

      const data = await response.json();
      // console.log("Response from server:", data);
      if (response.ok) {
        alert('Account setup successful. Please login.');
        navigate('/login');
      } else {
        throw new Error(data.message || 'Account setup failed');
      }
    } catch (error: any) {
      console.error("Error:", error.message);
      setError(error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
          boxShadow: `0px 4px 20px ${theme.palette.gray.main}`, // Apply gray shadow for depth
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: theme.palette.rose.main, // Apply rose color to title
            mb: 2,
          }}
        >
          Set Up Your Account
        </Typography>
        <img src={LogoOne} height={35} alt="The Alpine Studio Logo" />
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            disabled
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="code"
            label="Confirmation Code"
            name="code"
            autoComplete="off"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            disabled
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="Phone Number"
            name="phoneNumber"
            autoComplete="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            disabled
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
          />
          <Button
            onClick={handleConfirmSignUp}
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
              '&:hover': {
                backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
              },
            }}
          >
            Confirm and Sign In
          </Button>
          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default SetupAccount;
