// EditProductForm.tsx

import React, { useState, useEffect } from "react";
import { useAuth } from "../auth/AuthProvider";
import { Product } from "../auth/types";
import { useParams, useNavigate } from "react-router-dom";
import ProductForm from "./ProductForm";
import { Snackbar, Alert } from "@mui/material";

const EditProductForm = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchProductById, editProduct, fetchProducts } = useAuth();
  const [product, setProduct] = useState<Product | null>(null);
  const navigate = useNavigate();

  // Snackbar state
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (!id) {
          setErrorMessage("Product ID is missing.");
          navigate("/view-products");
          return;
        }
        const fetchedProduct = await fetchProductById(id);
        setProduct(fetchedProduct);
      } catch (error) {
        console.error("Failed to fetch product:", error);
        setErrorMessage("Failed to fetch product. Please try again.");
        navigate("/view-products"); // Redirect if fetching fails
      }
    };

    fetchProduct();
  }, [id, fetchProductById, navigate]);

  // Handlers for closing the Snackbar
  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(null);
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ProductForm
        product={product} // Pass the fetched product
        onSave={async (updatedProduct) => {
          try {
            // console.log("Editing product:", updatedProduct); // Debugging log
            await editProduct(updatedProduct); // API call to editProduct
            // console.log("Product edited successfully."); // Debugging log
            navigate("/view-products");
            await fetchProducts(); // Refresh the product list
            // Optionally, you can pass success messages to ProductForm
          } catch (error) {
            console.error("Failed to update product:", error);
            setErrorMessage("Failed to update product. Please try again.");
          }
        }}
      />

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProductForm;
