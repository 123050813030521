import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useSidebar } from "../context/AdminNavBarContext"; // Sidebar context to manage padding
import { useTheme } from "@mui/material/styles"; // Theme for colors

const Dashboard: React.FC = () => {
  const { expanded } = useSidebar(); // Get the expanded state of the sidebar
  const theme = useTheme(); // Access theme colors

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        mt: 4,
        ml: expanded ? '240px' : '60px', // Adjust padding based on sidebar state
        transition: "margin-left 0.3s ease", // Smooth transition for margin
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          color: theme.palette.rose.main, // Use rose color for the heading
          fontWeight: "bold",
        }}
      >
        Admin Dashboard
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="/add-product"
        sx={{
          backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for the button
          '&:hover': {
            backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
          },
        }}
      >
        Add Product
      </Button>
      <Button
        variant="contained"
        component={Link}
        to="/view-products"
        sx={{
          backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for the button
          '&:hover': {
            backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
          },
        }}
      >
        View Products
      </Button>
    </Box>
  );
};

export default Dashboard;
