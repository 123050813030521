import React, { useState, useEffect } from "react";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { useAuth } from "../auth/AuthProvider";
import { useTheme } from "@mui/material/styles"; // Import theme

const SetupTOTP: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [userCode, setUserCode] = useState<string>("");
  const [totpSecret, setTotpSecret] = useState<string>("");
  const email: string = String(location.state?.email || "");
  const accessToken: string = String(location.state?.access_token || "");
  const { setupTOTP } = useAuth();
  const theme = useTheme(); // Access theme colors

  useEffect(() => {
    if (!email || !accessToken) {
      setError("Email or access token is missing");
      return;
    }

    const secret = location.state?.totp_secret;
    if (secret) {
      setTotpSecret(secret);
    } else {
      setError("TOTP secret is missing");
    }
  }, [email, accessToken]);

  const handleQRCodeScan = async () => {
    if (email && accessToken && userCode) {
      try {
        await setupTOTP(email, accessToken, userCode);
        navigate("/dashboard");
      } catch (error: any) {
        console.error("TOTP setup error:", error);
        setError(error.message || "An error occurred during TOTP setup.");
      }
    } else {
      setError("Email, access token, or user code is missing");
    }
  };

  const qrCodeValue = `otpauth://totp/${encodeURIComponent(
    email
  )}?secret=${encodeURIComponent(totpSecret)}&issuer=${encodeURIComponent(
    "TheAlpineStudio"
  )}`;

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
          boxShadow: `0px 4px 20px ${theme.palette.gray.main}`, // Apply gray shadow for depth
        }}
      >
        <Typography
          component="h2"
          variant="h6"
          sx={{
            color: theme.palette.rose.main, // Apply rose color to the text
            mb: 2,
            textAlign: "center",
          }}
        >
          Please download the Google Authenticator app from the App Store or
          Google Play Store.
        </Typography>
        <Typography
          component="h2"
          variant="h6"
          sx={{
            color: theme.palette.rose.main, // Apply rose color to the text
            mb: 2,
            textAlign: "center",
          }}
        >
          Scan the QR code with your authenticator app:
        </Typography>
        {totpSecret && <QRCode value={qrCodeValue} />}
        <TextField
          label="TOTP Code"
          value={userCode}
          onChange={(e) => setUserCode(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
        />
        <Button
          onClick={handleQRCodeScan}
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
            '&:hover': {
              backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
            },
          }}
        >
          Verify Code
        </Button>
        {error && (
          <Typography variant="body2" color="error">
            {error}
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default SetupTOTP;
