import React from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import { useAuth } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles"; // Import theme
import { useSidebar } from "../context/AdminNavBarContext"; // Import Sidebar context to adjust padding

const Navbar: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme(); // Access theme colors
  const { expanded } = useSidebar(); // Get the expanded state of the sidebar

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: theme.palette.cadetGray.main, // Apply cadetGray for the AppBar background
        transition: "padding-left 0.3s ease", // Smooth transition for padding
        paddingLeft: expanded ? "240px" : "60px", // Adjust padding based on sidebar state
      }}
    >
      <Toolbar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            flexGrow: 1,
            color: theme.palette.lightCyan.main, // Apply lightCyan for the title text color
          }}
        >
          The Alpine Studio
        </Typography>
        {isAuthenticated && (
          <Button
            color="inherit"
            onClick={handleLogout}
            sx={{
              color: theme.palette.rose.main, // Apply rose for the logout button text color
              '&:hover': {
                color: theme.palette.rose.dark, // Darker shade on hover
              },
            }}
          >
            Logout
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
