// src/admin/auth/types.ts

export type Category =
  | "All"
  | "Shirts"
  | "Hoodies"
  | "Limited Edition"
  | "Prints"
  | "Stickers"
  | "Original Scratchboards";

export interface Price {
  amount: number;
  currency: string;
}

export interface PriceInput {
  amount?: number; // amount is optional for form input
  currency: string;
}

export interface Size {
  id: string; // Unique identifier
  size?: string;
  price: {
    amount?: number;
    currency: string;
  };
}

export interface SizeWithId extends Size {
  id: string;
}

export interface Product {
  id: string;
  title: string;
  description: string;
  category: Category;
  sizes: Size[];
  prices: Price[];
  imageUrl: string;
  sold: boolean;
}

export const categories: Category[] = [
  "All",
  "Shirts",
  "Hoodies",
  "Limited Edition",
  "Prints",
  "Stickers",
  "Original Scratchboards",
];
