// src/admin/components/ProductForm.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  Alert,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useSidebar } from "../context/AdminNavBarContext"; // Import SidebarContext
import { useTheme } from "@mui/material/styles"; // Import theme
import {
  Product,
  Size,
  categories,
  Category,
  Subcategory,
  subcategoriesMap,
} from "../auth/types"; // Import categories, subcategories, and types
import { v4 as uuidv4 } from "uuid"; // For generating unique IDs
import { SelectChangeEvent } from "@mui/material/Select"; // Import SelectChangeEvent

const heightOptions = [
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "36",
];
const widthOptions = [
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "36",
];
const clothingSizeOptions = ["XS", "S", "M", "L", "XL", "XXL"];

const ProductForm = ({
  onSave,
  product,
}: {
  onSave: (product: Product, file?: File | null) => void;
  product?: Product;
}) => {
  const [title, setTitle] = useState<string>(product?.title || "");
  const [description, setDescription] = useState<string>(
    product?.description || ""
  );
  const [sizes, setSizes] = useState<Size[]>(product?.sizes || []);
  const isEditing = !!product;
  const [selectedImageFile, setSelectedImageFile] = useState<File | null>(null);

  // Initialize 'category' and 'subcategories'
  const [category, setCategory] = useState<Exclude<Category, "All">>(
    product && product.category !== "All" ? product.category : "Originals" // Default to a valid category
  );
  const [subcategories, setSubcategories] = useState<Subcategory[]>(
    product?.subcategories || [subcategoriesMap[category][0]]
  );
  const [preview, setPreview] = useState<string>("");

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const theme = useTheme(); // Access theme colors
  const { expanded } = useSidebar(); // Get sidebar state

  // Handle category change
  const handleCategoryChange = (
    e: SelectChangeEvent<Exclude<Category, "All">>
  ) => {
    const value = e.target.value as Exclude<Category, "All">;
    setCategory(value);
    const newSubcategories = subcategoriesMap[value];
    setSubcategories([newSubcategories[0]]); // Set to first subcategory of selected category
    setSizes([]); // Reset sizes when category changes

    // console.log("Category changed to:", value);
    // console.log("Subcategories set to:", [newSubcategories[0]]);
  };

  // Handle subcategories change
  const handleSubcategoriesChange = (
    event: SelectChangeEvent<typeof subcategories>
  ) => {
    const {
      target: { value },
    } = event;
    setSubcategories(
      // On autofill we get a stringified value.
      typeof value === "string"
        ? (value.split(",") as Subcategory[])
        : (value as Subcategory[])
    );
    setSizes([]); // Reset sizes when subcategories change

    // console.log("Subcategories changed to:", value);
  };

  useEffect(() => {
    const newSizes: Size[] = [];

    if (category === "Apparel & Stickers") {
      if (
        subcategories.includes("Shirts") ||
        subcategories.includes("Hoodies")
      ) {
        newSizes.push(
          ...clothingSizeOptions.map((size) => ({
            size,
            price: { amount: 0, currency: "USD" },
          }))
        );
      }

      if (subcategories.includes("Stickers")) {
        newSizes.push({
          size: "Sticker",
          price: sizes.find((s) => s.size === "Sticker")?.price || {
            amount: 0,
            currency: "USD",
          },
        });
      }

      setSizes(newSizes);
    } else if (
      category === "Limited Edition Prints" ||
      category === "Originals"
    ) {
      if (sizes.length === 0) {
        setSizes([{ size: "8x10", price: { amount: 0, currency: "USD" } }]);
      }
    } else if (category === "Open Edition Prints") {
      const openEditionSizes: Size[] = [];

      if (subcategories.includes("Prints")) {
        if (!sizes.some((size) => size.size && size.size !== "Sticker")) {
          openEditionSizes.push({
            size: "8x10",
            price: { amount: 0, currency: "USD" },
          });
        }
      }

      if (subcategories.includes("Stickers")) {
        openEditionSizes.push({
          size: "Sticker",
          price: sizes.find((s) => s.size === "Sticker")?.price || {
            amount: 0,
            currency: "USD",
          },
        });
      }

      setSizes([
        ...sizes.filter((s) => s.size !== "Sticker"),
        ...openEditionSizes,
      ]);
    } else {
      setSizes([]);
    }
  }, [category, subcategories]);

  const handleAddSize = () => {
    // Add a new print size
    setSizes([
      ...sizes,
      { size: "8x10", price: { amount: 0, currency: "USD" } },
    ]);
  };

  const handlePriceChange = (index: number, value: string) => {
    const amount = value === "" ? undefined : parseFloat(value);

    const updatedSizes = sizes.map((size, i) =>
      i === index ? { ...size, price: { ...size.price, amount } } : size
    );
    setSizes(updatedSizes);
  };

  const handleSizeChange = (
    index: number,
    field: "height" | "width",
    value: string
  ) => {
    const updatedSizes = sizes.map((size, i) => {
      if (i !== index) return size;
      if (
        category === "Limited Edition Prints" ||
        category === "Originals" ||
        (category === "Open Edition Prints" && subcategories.includes("Prints"))
      ) {
        let newSize = size.size || "";
        if (field === "height") {
          const width = newSize.split("x")[1] || "";
          newSize = `${value}x${width}`;
        } else if (field === "width") {
          const height = newSize.split("x")[0] || "";
          newSize = `${height}x${value}`;
        }
        return { ...size, size: newSize };
      }
      return size;
    });
    setSizes(updatedSizes);
  };

  const handleRemoveSize = (index: number) => {
    setSizes(sizes.filter((_, i) => i !== index));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setSelectedImageFile(file);

      // Generate a local object URL or read as base64 for preview only
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === "string") {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage(null); // Reset error message
    setSuccessMessage(null); // Reset success message
    setIsSubmitting(true); // Set loading state

    // Validate required fields
    if (title.trim() === "" || description.trim() === "") {
      setErrorMessage("Title and Description are required.");
      setIsSubmitting(false);
      return;
    }

    if (subcategories.length === 0) {
      setErrorMessage("At least one subcategory must be selected.");
      setIsSubmitting(false);
      return;
    }

    // Additional validations based on category and subcategories
    if (category === "Apparel & Stickers") {
      // Validate apparel sizes and prices
      for (const size of sizes) {
        if (size.price.amount === undefined || size.price.amount < 0) {
          setErrorMessage("Please provide valid prices for all sizes.");
          setIsSubmitting(false);
          return;
        }
      }
    } else if (
      category === "Limited Edition Prints" ||
      category === "Originals" ||
      category === "Open Edition Prints"
    ) {
      // Validate sizes and prices
      for (const size of sizes) {
        if (size.size === "Sticker") {
          // Validate sticker price
          if (size.price.amount === undefined || size.price.amount < 0) {
            setErrorMessage("Please provide a valid price for Stickers.");
            setIsSubmitting(false);
            return;
          }
        } else {
          // Validate print sizes and prices
          if (
            !size.size ||
            size.price.amount === undefined ||
            size.price.amount < 0
          ) {
            setErrorMessage(
              "Please provide valid sizes and prices for prints."
            );
            setIsSubmitting(false);
            return;
          }
        }
      }
    }

    try {
      const newProduct: Product = {
        id: product?.id || uuidv4(), // Generate unique ID if new product
        title: title.trim(),
        description: description.trim(),
        sizes,
        imageUrl: "",
        category, // Exclude "All"
        subcategories: subcategories, // Multiple subcategories
        sold: product?.sold || false,
      };

      // console.log("Submitting product:", newProduct); // Debugging statement
      // console.log("onSave called with file:", selectedImageFile);

      await onSave(newProduct, selectedImageFile); // Use the onSave prop
      setSuccessMessage(
        product
          ? "Product updated successfully!"
          : "Product added successfully!"
      );

      // Clear form fields if creating a new product
      if (!product) {
        setTitle("");
        setDescription("");
        setCategory("Originals"); // Reset to default category
        setSubcategories([subcategoriesMap["Originals"][0]]); // Reset to default subcategories
        setSelectedImageFile(null);
        setSizes([]);
      }
    } catch (error) {
      console.error("Failed to save product", error);
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("Failed to save product. Please try again.");
      }
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  // Handlers for closing Snackbar alerts
  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessMessage(null);
  };

  const handleCloseError = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorMessage(null);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
        ml: expanded ? "240px" : "60px", // Adjust margin-left based on sidebar state
        transition: "margin-left 0.3s ease", // Smooth transition when sidebar toggles
        bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          color: theme.palette.rose.main, // Apply rose color to title
          fontWeight: "bold",
        }}
      >
        {product ? "Edit Product" : "Create Product"}
      </Typography>

      {/* Success Snackbar */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      {/* Error Snackbar */}
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} justifyContent="center">
        {/* Product Details */}
        <Grid item xs={12} md={6}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // won't fire if disabled
            variant="outlined"
            fullWidth
            required
            disabled={isEditing} // Make it read-only in edit mode
            sx={{
              backgroundColor: theme.palette.background.paper,
              mb: 2,
            }}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            fullWidth
            required
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          />
        </Grid>

        {/* Category Selection */}
        <Grid item xs={12} md={6}>
          <FormControl fullWidth required>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              label="Category"
              onChange={handleCategoryChange}
              sx={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {categories
                .filter((cat) => cat !== "All") // Exclude "All" for product categories
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Subcategory Selection */}
        {(category === "Apparel & Stickers" ||
          category === "Open Edition Prints" ||
          category === "Originals" ||
          category === "Limited Edition Prints") && (
          <Grid item xs={12} md={6}>
            <FormControl fullWidth required>
              <InputLabel>Subcategories</InputLabel>
              <Select
                multiple
                value={subcategories}
                label="Subcategories"
                onChange={handleSubcategoriesChange}
                renderValue={(selected) => (selected as string[]).join(", ")}
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                {subcategoriesMap[category].map((option) => (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={subcategories.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Conditionally render form fields based on subcategories */}
        {/* For Apparel */}
        {/* For Apparel & Stickers */}
        {category === "Apparel & Stickers" &&
          subcategories.some((sub) =>
            ["Shirts", "Hoodies", "Stickers"].includes(sub)
          ) &&
          sizes.map((size, index) => {
            if (size.size === "Sticker") {
              return (
                <Grid item xs={12} md={6} key={`sticker-${index}`}>
                  <TextField
                    label="Sticker Price"
                    type="number"
                    value={size.price.amount ?? ""}
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              );
            }

            return (
              <Grid item xs={12} md={6} key={`size-${index}`}>
                <FormControl fullWidth required sx={{ mb: 2 }}>
                  <TextField
                    label={`Price for ${size.size}`}
                    type="number"
                    value={size.price.amount ?? ""}
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Grid>
            );
          })}

        {/* For Prints */}
        {(category === "Limited Edition Prints" ||
          category === "Originals" ||
          (category === "Open Edition Prints" &&
            (subcategories.includes("Prints") ||
              subcategories.includes("Stickers")))) &&
          sizes.map((size, index) => {
            if (size.size === "Sticker") {
              // Render fields for sticker
              return (
                <Grid item xs={12} md={6} key={`sticker-${index}`}>
                  <TextField
                    label="Sticker Price"
                    type="number"
                    value={size.price.amount || ""}
                    onChange={(e) => handlePriceChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    required
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              );
            } else if (size.size) {
              // Render fields for prints
              const [currentHeight, currentWidth] = size.size
                ? size.size.split("x")
                : ["", ""];
              return (
                <React.Fragment key={`print-${index}`}>
                  <Grid item xs={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Height</InputLabel>
                      <Select
                        value={currentHeight}
                        label="Height"
                        onChange={(e) =>
                          handleSizeChange(index, "height", e.target.value)
                        }
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                      >
                        {heightOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl fullWidth required>
                      <InputLabel>Width</InputLabel>
                      <Select
                        value={currentWidth}
                        label="Width"
                        onChange={(e) =>
                          handleSizeChange(index, "width", e.target.value)
                        }
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                        }}
                      >
                        {widthOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Price"
                      type="number"
                      value={size.price.amount || ""}
                      onChange={(e) => handlePriceChange(index, e.target.value)}
                      variant="outlined"
                      fullWidth
                      required
                      sx={{ backgroundColor: theme.palette.background.paper }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleRemoveSize(index)}
                    >
                      Remove
                    </Button>
                  </Grid>
                </React.Fragment>
              );
            } else {
              return null;
            }
          })}

        {/* Add Size Button for Prints */}
        {(category === "Limited Edition Prints" ||
          category === "Originals" ||
          (category === "Open Edition Prints" &&
            subcategories.includes("Prints"))) && (
          <Grid item xs={12}>
            <Button
              onClick={handleAddSize}
              variant="contained"
              sx={{ backgroundColor: theme.palette.rose.main }}
            >
              Add Size
            </Button>
          </Grid>
        )}

        {/* Image Upload */}
        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ borderColor: theme.palette.gray.main }}
          >
            Upload Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
        </Grid>

        {/* Image Preview */}
        <Grid item xs={12} md={6}>
          {preview && (
            <Box
              component="img"
              src={preview}
              alt="Image Preview"
              sx={{
                maxHeight: 200,
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          )}
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{
              backgroundColor: theme.palette.lightBlue.main,
              "&:hover": { backgroundColor: theme.palette.lightBlue.dark },
            }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
