import React, { useState } from "react";
import { Box, Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import LogoOne from "./LogoOne.png";
import { useTheme } from "@mui/material/styles"; // Import theme

const SignUp: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const { signup, confirmSignUp } = useAuth();
  const theme = useTheme(); // Access theme colors

  const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    try {
      await signup(email, password, username, phoneNumber);
      setIsCodeSent(true);
    } catch (error: any) {
      console.error("Sign up error:", error);
      setError(error.message || "An error occurred during sign-up.");
    }
  };

  const handleConfirmSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);

    try {
      const { totp_secret, access_token } = await confirmSignUp(email, confirmationCode, password, username, phoneNumber);
      navigate("/setup-totp", {
        state: { email, password, totp_secret, access_token }, // Pass email, password, TOTP secret, and accessToken
      });
    } catch (error: any) {
      console.error("Confirmation error:", error);
      setError(error.message || "An error occurred during confirmation.");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper
        elevation={3}
        sx={{
          mt: 8,
          padding: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
          boxShadow: `0px 4px 20px ${theme.palette.gray.main}`, // Apply gray shadow for depth
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{
            color: theme.palette.rose.main, // Apply rose color to title
            mb: 2,
          }}
        >
          {isCodeSent ? "Confirm Sign Up" : "Sign Up"}
        </Typography>
        <img src={LogoOne} height={35} alt="The Alpine Studio Logo" />
        <Box component="form" onSubmit={isCodeSent ? handleConfirmSignUp : handleSignUp} sx={{ mt: 1 }}>
          {!isCodeSent ? (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="username"
                label="Username"
                type="text"
                id="username"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="phoneNumber"
                label="Phone Number"
                type="text"
                id="phoneNumber"
                autoComplete="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
                  '&:hover': {
                    backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
                  },
                }}
              >
                Sign Up
              </Button>
            </>
          ) : (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmationCode"
                label="Confirmation Code"
                type="text"
                id="confirmationCode"
                autoComplete="off"
                value={confirmationCode}
                onChange={(e) => setConfirmationCode(e.target.value)}
                sx={{ backgroundColor: theme.palette.background.paper }} // Light background for input
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: theme.palette.lightBlue.main, // Use lightBlue for button
                  '&:hover': {
                    backgroundColor: theme.palette.lightBlue.dark, // Darker shade on hover
                  },
                }}
              >
                Confirm Sign Up
              </Button>
            </>
          )}
          {error && <Typography variant="body2" color="error">{error}</Typography>}
        </Box>
      </Paper>
    </Container>
  );
};

export default SignUp;
