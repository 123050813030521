// AddProductForm.tsx

import React from "react";
import { useAuth } from "../auth/AuthProvider";
import { Product } from "../auth/types";
import { useNavigate } from "react-router-dom";
import ProductForm from "./ProductForm";

const AddProductForm = () => {
  const { uploadProduct, fetchProducts } = useAuth(); // Ensure uploadProduct and fetchProducts are available
  const navigate = useNavigate();

  const handleSave = async (newProduct: Product) => {
    try {
      console.log("Uploading product to API:", newProduct); // Debugging log
      await uploadProduct(newProduct); // API call to uploadProduct
      console.log("Product uploaded successfully."); // Debugging log
      await fetchProducts(); // Refresh the product list
      alert("Product added successfully!");
      navigate("/view-products"); // Redirect to product view page
    } catch (error) {
      console.error("Failed to add product:", error);
      alert("Failed to add product. Please try again.");
    }
  };

  return <ProductForm onSave={handleSave} />;
};

export default AddProductForm;
