export const configLogin = {
  API_ENDPOINT_LOGIN: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/auth-login",
};

export const configPass = {
  API_ENDPOINT_NEWPASSWORD: "https://q27fij77i3.execute-api.us-west-2.amazonaws.com/dev/change-password",
};

export const configVerify = {
  API_ENDPOINT_VERIFY: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/verify-mfa",
};

export const configPreSignup = {
  API_ENDPOINT_PRESIGNUP: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/presign",
};

export const configPostConfirmation = {
  API_ENDPOINT_POSTCONFIRM: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/post-confirmation",
};

export const configSignup = {
  API_ENDPOINT_SIGNUP: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/signup",
};

export const configConfirmSignup = {
  API_ENDPOINT_CONFIRMSIGNUP: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/confirm-signup",
};

export const configTotp = {
  API_ENDPOINT_TOTP: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/totp-secret",
};

export const configRefreshToken = {
  API_ENDPOINT_REFRESH: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/refresh-token",
}


export const configUploadProduct = {
  API_ENDPOINT_UPLOAD_PRODUCT: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/products",
};

export const configFetchProducts = {
  API_ENDPOINT_FETCH_PRODUCTS: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/admin-list-products",
};

export const configMarkProductAsSold = {
  API_ENDPOINT_MARK_PRODUCT_AS_SOLD: "https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/sold-product",
};

export const configDeleteProduct = {
  API_ENDPOINT_DELETE_PRODUCT: (id: string) =>
    `https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/products/${id}`,
};

export const configEditProduct = {
  API_ENDPOINT_EDIT_PRODUCT: (id: string) =>
    `https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/products/${id}`,
};

export const configFetchProductById = {
  API_ENDPOINT_FETCH_PRODUCT_BY_ID: (id: string) =>
    `https://f2trvidc5b.execute-api.us-west-2.amazonaws.com/dev/products/${id}`,
};

