import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useSidebar } from "../context/AdminNavBarContext"; // Use the SidebarContext
import { useTheme } from "@mui/material/styles"; // Use theme

const AdminSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { expanded, toggleSidebar } = useSidebar(); // Get expanded state from context
  const theme = useTheme(); // Get custom theme colors

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: expanded ? 240 : 60,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: expanded ? 240 : 60,
          boxSizing: "border-box",
          transition: "width 0.3s",
          backgroundColor: theme.palette.cadetGray.main, // Use cadetGray from theme
          color: theme.palette.lightCyan.main, // Use lightCyan for text
        },
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: expanded ? "flex-end" : "center",
          padding: "8px",
        }}
      >
        <IconButton onClick={toggleSidebar} sx={{ color: theme.palette.lightCyan.main }}>
          {expanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          padding: expanded ? "16px" : "8px",
          color: theme.palette.rose.main, // Use rose for the title
        }}
      >
        {expanded && "Admin Panel"}
      </Typography>

      <List>
        <ListItem
          button
          onClick={() => handleNavigation("/add-product")}
          selected={location.pathname === "/add-product"}
          sx={{
            backgroundColor: location.pathname === "/add-product" ? theme.palette.lightBlue.main : "inherit",
            "&:hover": { backgroundColor: theme.palette.gray.main },
            color: theme.palette.lightCyan.main, // Ensure icon and text color follow the theme
          }}
        >
          <ListItemIcon sx={{ color: theme.palette.lightCyan.main }}>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          {expanded && <ListItemText primary="Add Product" />}
        </ListItem>

        <ListItem
          button
          onClick={() => handleNavigation("/view-products")}
          selected={location.pathname === "/view-products"}
          sx={{
            backgroundColor: location.pathname === "/view-products" ? theme.palette.lightBlue.main : "inherit",
            "&:hover": { backgroundColor: theme.palette.gray.main },
            color: theme.palette.lightCyan.main, // Ensure icon and text color follow the theme
          }}
        >
          <ListItemIcon sx={{ color: theme.palette.lightCyan.main }}>
            <ViewListIcon />
          </ListItemIcon>
          {expanded && <ListItemText primary="View Products" />}
        </ListItem>
      </List>
    </Drawer>
  );
};

export default AdminSideBar;
