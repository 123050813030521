// src/admin/auth/types.ts

export type Category =
  | "All" // Used for filtering only
  | "Originals"
  | "Limited Edition Prints"
  | "Open Edition Prints"
  | "Apparel & Stickers";

export type Subcategory =
  | "Original Scratchboards"
  | "Prints"
  | "Stickers"
  | "Shirts"
  | "Hoodies";

export interface FetchProductsParams {
  category?: string;
  subcategories?: Subcategory[];
  sortOrder?: string;
  searchKeyword?: string;
  page?: number;
  limit?: number;
}

export interface FetchProductsResponse {
  items: Product[];
  totalPages: number;
}

export interface Price {
  amount: number;
  currency: string;
}

export interface PriceInput {
  amount?: number; // amount is optional for form input
  currency: string;
}

export interface Size {
  size?: string;
  price: {
    amount?: number;
    currency: string;
  };
}

export interface SizeWithId extends Size {
  id: string;
}

export interface Product {
  id: string;
  title: string;
  description: string;
  category: Category;
  subcategories: Subcategory[];
  sizes: Size[];
  imageUrl: string;
  sold: boolean;
}

export const categories: Category[] = [
  "All",
  "Originals",
  "Limited Edition Prints",
  "Open Edition Prints",
  "Apparel & Stickers",
];

// Define subcategories mapping based on category
export const subcategoriesMap: {
  [key in Exclude<Category, "All">]: Subcategory[];
} = {
  Originals: ["Original Scratchboards"],
  "Limited Edition Prints": ["Prints"],
  "Open Edition Prints": ["Prints"],
  "Apparel & Stickers": ["Shirts", "Hoodies", "Stickers"],
};

export interface AddressParam {
  line1: string;
  line2?: string;
  city?: string;
  state?: string;
  postal_code?: string;
  country?: string;
}

// src/types.ts

export interface CartItem {
  id: string;
  title: string;
  price: number;
  imageUrl: string;
  selectedSubCategory: string;
  quantity: number;
  selectedSize: Size;
  selectedPrice: Price;
}

export interface ShippingDetails {
  address: {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  };
  name: string;
}

export interface CheckoutItem {
  id: string;
  name: string;
  subcategory: string;
  size: string | undefined;
  currency: string;
  quantity: number;
  price: number; // in cents
  imageUrl: string;
}

export interface CheckoutEvent {
  items: CheckoutItem[];
  customer_email: string;
  shipping_details: ShippingDetails;
  billing_details: ShippingDetails;
}

export interface Order {
  order_id: string;
  customer_email: string;
  amount_total: number;
  currency: string;
  shipping_details: ShippingDetails;
  products: CartItem[];
  payment_intent_id: string;
  status: string; // e.g., "Pending", "Succeeded"
  created_at: string;
  user_email: string;
}
